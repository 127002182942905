import classNames from 'classnames';
import React from 'react';

import ArrowIcon from '@/assets/svg/arrow.svg';

interface ArrowProps {
  onClick: () => void;
  isNext?: boolean;
  disabled: boolean;
  className?: string;
}

const ArrowComp: React.FC<ArrowProps> = ({
  isNext,
  onClick,
  disabled,
  className,
}) => {
  return (
    <div
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={classNames(
        'bg-primary py-5 px-6 lg:py-6 lg:px-8 2xl:py-10 2xl:px-12 rounded-full transition-all duration-500',
        disabled
          ? 'opacity-30 cursor-default'
          : 'cursor-pointer hover:-translate-y-[2%] hover:shadow-csm',
        className,
      )}
    >
      <ArrowIcon
        width={38}
        height={16}
        className={classNames('fill-darkMain', { 'rotate-180': !isNext })}
      />
    </div>
  );
};

export default ArrowComp;
